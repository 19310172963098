(function () {
  // Initialise testimonials
  function init() {
    var mySwiper = new Swiper(".swiper-container", {
      loop: true,
      navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
      },
      pagination: {
        clickable: true,
        el: ".swiper-pagination",
      },
      slidesPerView: "auto",
      breakpoints: {
        769: {
          slidesPerView: 2,
        },
        961: {
          slidesPerView: 3,
        },
      },
    });
  }

  // Perform init
  init();
})();
